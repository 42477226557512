import { useAppStore } from '@/stores/AppStore'
import { BFAlbum, BFPicture } from '@/type/BFAlbum'
import axios from 'axios'

export interface BFRepository {
  fetchBFAlbum: () => Promise<BFAlbum[]>
  fetchAlbumPicture: (album_id: Number) => Promise<BFPicture[]>
  fetchTags: () => Promise<any>
}

export function useBFRepository(clinic_uuid: string): BFRepository {
  const appStore = useAppStore()

  async function fetchBFAlbum(): Promise<BFAlbum[]> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/bf-showcase/?c=${clinic_uuid}`,
    )
    return response.data
  }

  async function fetchAlbumPicture(album_id: Number): Promise<BFPicture[]> {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/bf-showcase/item/`,
      {
        params: {
          c: clinic_uuid,
          album_id: album_id,
        },
      },
    )
    return response.data
  }

  async function fetchTags() {
    const response = await axios.get(
      `${appStore.url}/api/v1/crm/line-app/p/bf-showcase/tag/?c=${clinic_uuid}`,
    )
    return response.data
  }

  return {
    fetchBFAlbum,
    fetchAlbumPicture,
    fetchTags,
  }
}
