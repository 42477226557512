<script setup lang="ts">
import { BFPicture } from '@/type/BFAlbum'
import { computed, ref } from 'vue'

export interface Props {
  visible: boolean
  album_name: string
  pictures: BFPicture[]
}

const emptyPicture: BFPicture = {
  id: 0,
  pic: '',
}

const emit = defineEmits({
  // No validation
  close: null,
})

// props
const props = defineProps<Props>()

// ui toggle
const show = ref(false)
const index = ref(0)
let images: string[] = []

// computed
const table = computed(() => {
  if (!props.pictures) return

  const chunksize = 3
  const chunk = []

  for (let i = 0; i < props.pictures.length; i += chunksize) {
    chunk.push(props.pictures.slice(i, i + chunksize))
  }

  if (chunk[chunk.length - 1].length % chunksize < chunksize) {
    const fill_in_size = chunksize - chunk[chunk.length - 1].length
    for (let i = 0; i < fill_in_size; i++) {
      chunk[chunk.length - 1].push(emptyPicture)
    }
  }

  return chunk
})

// method
function close() {
  emit('close')
}

function onShow(picture_id: number) {
  index.value = props.pictures.findIndex(picture => picture.id === picture_id)
  images = props.pictures.map(picture => picture.pic) // get only image url
  show.value = true
}

function onCloseShow() {
  show.value = false
}

function onChange(newIndex: number) {
  index.value = newIndex
}
</script>

<template>
  <van-popup
    :show="props.visible"
    :duration="0.3"
    position="bottom"
    :style="{ height: '100%' }"
  >
    <van-nav-bar
      :title="props.album_name"
      :left-text="$t('bf_showcase.back_button')"
      left-arrow
      @click-left="close"
    />

    <div style="margin: 20px">
      <van-row
        v-for="(row, num_row) in table"
        :key="`row ${num_row}`"
        type="flex"
        gutter="5"
        justify="center"
      >
        <van-col
          v-for="(col, num_col) in row"
          :key="`col ${num_col}`"
          style="margin-top: 10px"
          span="8"
        >
          <div v-if="col.id === 0"></div>

          <div v-else>
            <div
              @click="onShow(col.id)"
              style="
                min-height: 100px;
                aspect-ratio: 1 / 1;
                text-align: center;
                border-radius: 10px;
                border: 1px #dddddd solid;
              "
            >
              <van-image
                radius="10"
                height="100%"
                width="100%"
                fit="cover"
                :src="col.pic"
              />
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </van-popup>

  <van-image-preview
    :on-close="onCloseShow"
    :show="show"
    :closeable="true"
    :start-position="index"
    :images="images"
    :loop="false"
    @change="onChange"
    @click="onCloseShow"
  >
    <template v-slot:index
      >{{ $t('bf_showcase.picutre_index') }}: {{ index + 1 }}</template
    >
  </van-image-preview>
</template>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
</style>
